import React, { useRef } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import PageDetails from '../components/PageDetails';
import { adjustSearchParams } from '../components/helper-functions';
import ReactToPrint from 'react-to-print';
import '../sass/pages/_jobDescription.scss'

const lang = process.env.LANG;
const isFrench = lang === 'FR';
const isLocal = ['dev', 'development'].includes( process.env.NODE_ENV );


const JobDescription = ( {pageContext} ) => {
    const en_url = process.env.EN_URL + '/' + (isFrench ? pageContext.correspondingPosPath ? pageContext.correspondingPosPath : '/fr/recherche/' : pageContext.posPath);
    const fr_url = process.env.FR_URL + '/' + (isFrench ? pageContext.posPath : pageContext.correspondingPosPath ? pageContext.correspondingPosPath : '/en/search/');
    const searchText = isFrench ? 'Recherche' : 'Search';
    const searchURL = isFrench ? fr_url : en_url;
    pageContext.appointmentProcessNo = pageContext.appointmentProcessNo.toString().slice( 0, 8 )
    let componentRef = useRef();
    const buttonContainerStyle = {
        'display': 'flex', 'flex-direction': 'row', 'flex-wrap': 'wrap', 'justify-content': 'space-between'


    }
    return (<>
        <Header
            title={isFrench ? `${pageContext.title} ${pageContext.appointmentProcessNo} - ${pageContext.locationFr}` : `${pageContext.title} ${pageContext.appointmentProcessNo} - ${pageContext.location}`}
            description={'Global Affairs Careers'}
            hidden={false}
            lang={isFrench ? 'FR' : 'EN'}
            langLink={isFrench ? en_url : fr_url}
            breadcrumbs={[{text: searchText, url: searchURL}, {
                text: pageContext.title, url: '/' + pageContext.posPath
            }]}
            bodyClasses={'page-index'}/>

        <main style={{overflowWrap: "break-word" }} ref={( el ) => (componentRef = el)} property="mainContentOfPage" resource="#wb-main"
              className="main-content container pb-5" typeof="WebPageElement">

            <h1>{pageContext.title}</h1>
            <p style={{marginTop: 20}}>
                <b>{pageContext.lang === 'FR' ? 'Numéro du processus de sélection: ' : 'Selection Process Nº:'} </b> {pageContext.lang === 'FR' ? pageContext.appointmentProcessNo : pageContext.appointmentProcessNo}
            </p>
            <p style={{marginTop: 8}}>
                <b>{pageContext.lang === 'FR' ? 'Échelle salariale:' : 'Salary:'} </b> {pageContext.lang === 'FR' ? pageContext.salaryFr : pageContext.salary}
            </p>
            <p style={{marginTop: 8}}>
                <b>{pageContext.lang === 'FR' ? 'Type du contrat:' : 'Contract Type:'} </b> {pageContext.lang === 'FR' ? pageContext.contractTypeFr : pageContext.contractType}
            </p>
            <p style={{marginTop: 8}}>
                <b>{pageContext.lang === 'FR' ? 'Détails reliés au statut:' : 'Term Details:'} </b> {pageContext.lang === 'FR' ? pageContext.termDetailsFr : pageContext.termDetails}
            </p>
            <p style={{marginTop: 8}}>
                <b>{pageContext.lang === 'FR' ? 'Fonction du poste:' : 'Job Function:'} </b> {pageContext.lang === 'FR' ? pageContext.jobFunctionFr : pageContext.jobFunction}
            </p>
            <p style={{marginTop: 8}}>
                <b>{pageContext.lang === 'FR' ? 'Classification:' : 'Classification:'} </b> {pageContext.lang === 'FR' ? pageContext.classificationFr : pageContext.classification}
            </p>
            <p style={{marginTop: 8}}>
                <b>{pageContext.lang === 'FR' ? 'Lieu:' : 'Location:'} </b> {pageContext.lang === 'FR' ? pageContext.locationFr : pageContext.location}
            </p>
            <p style={{marginTop: 8}}>
                <b>{pageContext.lang === 'FR' ? 'Nombre de postes disponibles:' : 'Number of Vacancies:'} </b> {pageContext.lang === 'FR' ? pageContext.numberOfVacanciesFr : pageContext.numberOfVacancies}
            </p>
            <p style={{marginTop: 8}}>
                <b>{pageContext.lang === 'FR' ? 'Date de clôture:' : 'Closing date for application:'} </b> {`${pageContext.closeDateDay} ${adjustSearchParams( pageContext.closeDateMonth, pageContext.lang )}, ${pageContext.closeDateYear} ${pageContext.lang === 'FR' ? 'à' : 'at'} 23:59 ${pageContext.closeDateTimeZone}`}
            </p>
            {!pageContext.isLocal.includes( 'No / Non' ) ?
                <a target={'_blank'} rel={'noreferrer nofollow'} href={pageContext.formUrl}>
                    <button className="no-print btn btn-call-to-action"
                            type="button">{pageContext.lang === 'FR' ? 'Postuler ici ' : 'Apply Here'}</button>
                </a> : <></>}
            <div dangerouslySetInnerHTML={{__html: pageContext.description}}/>
            <div style={buttonContainerStyle}>
                {!pageContext.isLocal.includes( 'No / Non' ) ?
                    <a target={'_blank'} rel={'noreferrer nofollow'} href={pageContext.formUrl}>
                        <button className="no-print btn btn-call-to-action"
                                type="button">{pageContext.lang === 'FR' ? 'Postuler ici ' : 'Apply Here'}</button>
                    </a> : <></>}
                <ReactToPrint
                    trigger={() => <button type={'button'} style={{
                        marginBottom: '25px', marginTop: '15px'
                    }} className={'no-print btn btn-primary'}>{isFrench ? "Imprimer / télécharger en PDF" : "Print / Download as PDF"}</button>}
                    content={() => componentRef}
                />
            </div>
            <PageDetails lang={isFrench ? 'FR' : 'EN'} dateModified={pageContext.listDate}/>
        </main>
        <Footer lang={isFrench ? 'FR' : 'EN'}/>
    </>)
}

export default JobDescription;
