import React from "react";

const PageDetails = (props) => {
    if (props.lang==="FR") {
        return (
            <>
                {/*<div className="pagedetails">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-sm-6 col-md-5 col-lg-4">*/}
                {/*            <details className="brdr-0">*/}
                {/*                <summary className="btn btn-default text-center">Signaler un problème sur cette page*/}
                {/*                </summary>*/}
                {/*                <div className="well row">*/}
                {/*                    <div className="gc-rprt-prblm">*/}
                {/*                        <div className="cgc-rprt-prblm-frm gc-rprt-prblm-tggl">*/}
                {/*                            <form method="post" action="https://www.canada.ca/gc/services/notify">*/}
                {/*                                <fieldset>*/}
                {/*                                    <legend><span className="field-name">Veuillez cocher toutes les réponses pertinentes&nbsp;:</span>*/}
                {/*                                    </legend>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f1">*/}
                {/*                                            <input type="checkbox" name="problem1" value="Oui"*/}
                {/*                                                   id="gc-rprt-prblm-f1"/>Un lien, un bouton ou une*/}
                {/*                                            vidéo ne fonctionne pas*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem1" type="hidden" value="Non"/>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f2">*/}
                {/*                                            <input type="checkbox" name="problem2" value="Oui"*/}
                {/*                                                   id="gc-rprt-prblm-f2"/>Il y a une erreur*/}
                {/*                                            d'orthographe ou de grammaire*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem2" type="hidden" value="Non"/>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f3">*/}
                {/*                                            <input type="checkbox" name="problem3" value="Oui"*/}
                {/*                                                   id="gc-rprt-prblm-f3"/>Les renseignements sont*/}
                {/*                                            incomplets*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem3" type="hidden" value="Non"/>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f4">*/}
                {/*                                            <input type="checkbox" name="problem4" value="Oui"*/}
                {/*                                                   id="gc-rprt-prblm-f4"/>L'information n'est plus à*/}
                {/*                                            jour ou est erronée*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem4" type="hidden" value="Non"/>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f5">*/}
                {/*                                            <input type="checkbox" name="problem5" value="Oui"*/}
                {/*                                                   id="gc-rprt-prblm-f5"/>Je n'arrive pas à trouver ce*/}
                {/*                                            que je cherche*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem5" type="hidden" value="Non"/>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f6">*/}
                {/*                                            <input type="checkbox" name="problem6" value="Oui"*/}
                {/*                                                   id="gc-rprt-prblm-f6"/>Autre problème qui ne figure*/}
                {/*                                            pas sur cette liste*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem6" type="hidden" value="Non"/>*/}
                {/*                                    </div>*/}
                {/*                                </fieldset>*/}
                {/*                                <button type="submit" className="btn btn-primary wb-toggle"*/}
                {/*                                        data-toggle='{"stateOff": "hide", "stateOn": "show", "selector": ".gc-rprt-prblm-tggl"}'>Soumettre*/}
                {/*                                </button>*/}
                {/*                            </form>*/}
                {/*                        </div>*/}
                {/*                        <div className="gc-rprt-prblm-thnk gc-rprt-prblm-tggl hide">*/}
                {/*                            <h3>Merci de votre aide!</h3>*/}
                {/*                            <p>Vous ne recevrez pas de réponse. Pour toute question, s’il vous plaît <a*/}
                {/*                                href="https://www.canada.ca/fr/contact.html">contactez-nous</a>.</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </details>*/}
                {/*        </div>*/}
                {/*        <div*/}
                {/*            className="wb-share col-sm-4 col-md-3 col-sm-offset-2 col-md-offset-4 col-lg-offset-5 wb-init wb-share-inited"*/}
                {/*            data-wb-share="{&quot;lnkClass&quot;: &quot;btn btn-default btn-block&quot;}"*/}
                {/*            id="wb-auto-6">*/}
                {/*            <section id="shr-pg0" className="shr-pg modal-dialog modal-content overlay-def mfp-hide"*/}
                {/*                     tabindex="-1">*/}
                {/*                <header className="modal-header"><h2 className="modal-title" id="lbx-title">Partagez cette*/}
                {/*                    page</h2></header>*/}
                {/*                <div className="modal-body">*/}
                {/*                    <ul className="list-unstyled colcount-xs-2">*/}
                {/*                        <li><a*/}
                {/*                            href="https://bitly.com/a/bitmarklet?u=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860"*/}
                {/*                            className="shr-lnk bitly btn btn-default" rel={"noreferrer"} target="_blank">bitly</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://www.blogger.com/blog_this.pyra?t=&amp;u=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;n=Conseils%20de%20sant%C3%A9%20aux%20voyageurs"*/}
                {/*                            className="shr-lnk blogger btn btn-default" rel={"noreferrer"} target="_blank">Blogger</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://delicious.com/post?url=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;title=Conseils%20de%20sant%C3%A9%20aux%20voyageurs"*/}
                {/*                            className="shr-lnk delicious btn btn-default" rel={"noreferrer"} target="_blank">Delicious</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://digg.com/submit?phase=2&amp;url=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;title=Conseils%20de%20sant%C3%A9%20aux%20voyageurs"*/}
                {/*                            className="shr-lnk digg btn btn-default" rel={"noreferrer"} target="_blank">Digg</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://www.diigo.com/post?url=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;title=Conseils%20de%20sant%C3%A9%20aux%20voyageurs"*/}
                {/*                            className="shr-lnk diigo btn btn-default" rel={"noreferrer"} target="_blank">Diigo</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="mailto:?to=&amp;subject=Conseils%20de%20sant%C3%A9%20aux%20voyageurs&amp;body=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860%0A"*/}
                {/*                            className="shr-lnk email btn btn-default" rel={"noreferrer"} target="_blank">Courriel</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://www.facebook.com/sharer.php?u=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;t=Conseils%20de%20sant%C3%A9%20aux%20voyageurs"*/}
                {/*                            className="shr-lnk facebook btn btn-default" rel={"noreferrer"} target="_blank">Facebook</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=&amp;su=Conseils%20de%20sant%C3%A9%20aux%20voyageurs&amp;body=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860%0A"*/}
                {/*                            className="shr-lnk gmail btn btn-default" rel={"noreferrer"} target="_blank">Gmail</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://plus.google.com/share?url=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;hl=fr"*/}
                {/*                            className="shr-lnk googleplus btn btn-default" rel={"noreferrer"} target="_blank">Google+</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;title=Conseils%20de%20sant%C3%A9%20aux%20voyageurs&amp;ro=false&amp;summary=&amp;source="*/}
                {/*                            className="shr-lnk linkedin btn btn-default" rel={"noreferrer"} target="_blank">LinkedIn®</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://www.myspace.com/Modules/PostTo/Pages/?u=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;t=Conseils%20de%20sant%C3%A9%20aux%20voyageurs"*/}
                {/*                            className="shr-lnk myspace btn btn-default" rel={"noreferrer"} target="_blank">MySpace</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://www.pinterest.com/pin/create/button/?url=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;media=&amp;description=Conseils%20de%20sant%C3%A9%20aux%20voyageurs"*/}
                {/*                            className="shr-lnk pinterest btn btn-default" rel={"noreferrer"} target="_blank">Pinterest</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://reddit.com/submit?url=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;title=Conseils%20de%20sant%C3%A9%20aux%20voyageurs"*/}
                {/*                            className="shr-lnk reddit btn btn-default" rel={"noreferrer"} target="_blank">reddit</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://www.stumbleupon.com/submit?url=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;title=Conseils%20de%20sant%C3%A9%20aux%20voyageurs"*/}
                {/*                            className="shr-lnk stumbleupon btn btn-default" rel={"noreferrer"} target="_blank">StumbleUpon</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://www.tumblr.com/share/link?url=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860&amp;name=Conseils%20de%20sant%C3%A9%20aux%20voyageurs&amp;description="*/}
                {/*                            className="shr-lnk tumblr btn btn-default" rel={"noreferrer"} target="_blank">tumblr</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://twitter.com/home?status=Conseils%20de%20sant%C3%A9%20aux%20voyageurs%20https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860"*/}
                {/*                            className="shr-lnk twitter btn btn-default" rel={"noreferrer"} target="_blank">Twitter</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="http://compose.mail.yahoo.com/?to=&amp;subject=Conseils%20de%20sant%C3%A9%20aux%20voyageurs&amp;body=https%3A%2F%2Fvoyage.gc.ca%2Fvoyager%2Fsante-securite%2Fconseils-sante-voyageurs%2F226%3F_ga%3D2.11756276.1583564998.1641350860-443163423.1641350860%0A"*/}
                {/*                            className="shr-lnk yahoomail btn btn-default" rel={"noreferrer"} target="_blank">Yahoo! Mail</a>*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}
                {/*                    <p className="col-sm-12 shr-dscl">Aucun appui n’est accordé, soit de façon expresse ou*/}
                {/*                        tacite, à aucun produit ou service.</p>*/}
                {/*                    <div className="clearfix"></div>*/}
                {/*                </div>*/}
                {/*                <div className="modal-footer">*/}
                {/*                    <button type="button" className="btn btn-sm btn-primary pull-left popup-modal-dismiss"*/}
                {/*                            title="Fermer Fermer la fenêtre superposée (touche d'échappement)">Fermer<span*/}
                {/*                        className="wb-inv">Fermer la fenêtre superposée (touche d'échappement)</span>*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </section>*/}
                {/*            <a href="#shr-pg0" aria-controls="shr-pg0"*/}
                {/*               className="shr-opn wb-lbx btn btn-default btn-block wb-lbx-inited wb-init"*/}
                {/*               id="wb-auto-7"><span className="glyphicon glyphicon-share"></span>Partagez cette page</a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <dl id="wb-dtmd">*/}
                {/*        <dt>Date de modification&#160;:</dt>*/}
                {/*        <dd>*/}
                {/*            <time*/}
                {/*                property="dateModified">&nbsp;{props.dateModified ? props.dateModified : "2019-02-06"}</time>*/}
                {/*        </dd>*/}
                {/*    </dl>*/}
                {/*</div>*/}

            </>
        )
    } else {
        return (
            <>
                {/*<div className="pagedetails">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-sm-6 col-md-5 col-lg-4">*/}
                {/*            <details className="brdr-0">*/}
                {/*                <summary className="btn btn-default text-center">Report a problem on this page</summary>*/}
                {/*                <div className="well row">*/}
                {/*                    <div className="gc-rprt-prblm">*/}
                {/*                        <div className="gc-rprt-prblm-frm gc-rprt-prblm-tggl" id="wb-auto-4">*/}
                {/*                            <form method="post" action="https://www.canada.ca/gc/services/notify">*/}
                {/*                                <fieldset>*/}
                {/*                                    <legend><span*/}
                {/*                                        className="field-name">Please select all that apply:</span>*/}
                {/*                                    </legend>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f1">*/}
                {/*                                            <input type="checkbox" name="problem1" value="Yes"*/}
                {/*                                                   id="gc-rprt-prblm-f1"/>A link, button or video is not*/}
                {/*                                            working*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem1" type="hidden" value="No"/>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f2">*/}
                {/*                                            <input type="checkbox" name="problem2" value="Yes"*/}
                {/*                                                   id="gc-rprt-prblm-f2"/>It has a spelling mistake*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem2" type="hidden" value="No"/>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f3">*/}
                {/*                                            <input type="checkbox" name="problem3" value="Yes"*/}
                {/*                                                   id="gc-rprt-prblm-f3"/>Information is missing*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem3" type="hidden" value="No"/>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f4">*/}
                {/*                                            <input type="checkbox" name="problem4" value="Yes"*/}
                {/*                                                   id="gc-rprt-prblm-f4"/>Information is outdated or*/}
                {/*                                            wrong*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem4" type="hidden" value="No"/>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f5">*/}
                {/*                                            <input type="checkbox" name="problem5" value="Yes"*/}
                {/*                                                   id="gc-rprt-prblm-f5"/>I can't find what I'm looking*/}
                {/*                                            for*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem5" type="hidden" value="No"/>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="checkbox">*/}
                {/*                                        <label for="gc-rprt-prblm-f6">*/}
                {/*                                            <input type="checkbox" name="problem6" value="Yes"*/}
                {/*                                                   id="gc-rprt-prblm-f6"/>Other issue not in this list*/}
                {/*                                        </label>*/}
                {/*                                        <input name="problem6" type="hidden" value="No"/>*/}
                {/*                                    </div>*/}
                {/*                                </fieldset>*/}
                {/*                                <button type="submit"*/}
                {/*                                        className="btn btn-primary wb-toggle wb-init wb-toggle-inited"*/}
                {/*                                        data-toggle="{&quot;stateOff&quot;: &quot;hide&quot;, &quot;stateOn&quot;: &quot;show&quot;, &quot;selector&quot;: &quot;.gc-rprt-prblm-tggl&quot;}"*/}
                {/*                                        aria-controls="wb-auto-4 wb-auto-5">Submit*/}
                {/*                                </button>*/}
                {/*                            </form>*/}
                {/*                        </div>*/}
                {/*                        <div className="gc-rprt-prblm-thnk gc-rprt-prblm-tggl hide" id="wb-auto-5">*/}
                {/*                            <h3>Thank you for your help!</h3>*/}
                {/*                            <p>You will not receive a reply. For enquiries, please <a*/}
                {/*                                href="https://www.canada.ca/en/contact.html">contact us</a>.</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </details>*/}
                {/*        </div>*/}
                {/*        <div*/}
                {/*            className="wb-share col-sm-4 col-md-3 col-sm-offset-2 col-md-offset-4 col-lg-offset-5 wb-init wb-share-inited"*/}
                {/*            data-wb-share="{&quot;lnkClass&quot;: &quot;btn btn-default btn-block&quot;}"*/}
                {/*            id="wb-auto-6">*/}
                {/*            <section id="shr-pg0" className="shr-pg modal-dialog modal-content overlay-def mfp-hide">*/}
                {/*                <header className="modal-header"><h2 className="modal-title" id="lbx-title">Share this*/}
                {/*                    page</h2>*/}
                {/*                </header>*/}
                {/*                <div className="modal-body">*/}
                {/*                    <ul className="list-unstyled colcount-xs-2">*/}
                {/*                        <li><a*/}
                {/*                            href="https://www.blogger.com/blog_this.pyra?t=&amp;u=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page&amp;n=Search%20facets%2Ffilters%20results%20-%20Canada.ca"*/}
                {/*                            className="shr-lnk blogger btn btn-default"*/}
                {/*                            rel="noreferrer noopener">Blogger</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://www.diigo.com/post?url=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page&amp;title=Search%20facets%2Ffilters%20results%20-%20Canada.ca"*/}
                {/*                            className="shr-lnk diigo btn btn-default"*/}
                {/*                            rel="noreferrer noopener">Diigo</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="mailto:?to=&amp;subject=Search%20facets%2Ffilters%20results%20-%20Canada.ca&amp;body=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page%0A"*/}
                {/*                            className="shr-lnk email btn btn-default"*/}
                {/*                            rel="noreferrer noopener">Email</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page&amp;t=Search%20facets%2Ffilters%20results%20-%20Canada.ca"*/}
                {/*                            className="shr-lnk facebook btn btn-default"*/}
                {/*                            rel="noreferrer noopener">Facebook</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=&amp;su=Search%20facets%2Ffilters%20results%20-%20Canada.ca&amp;body=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page%0A"*/}
                {/*                            className="shr-lnk gmail btn btn-default"*/}
                {/*                            rel="noreferrer noopener">Gmail</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page&amp;title=Search%20facets%2Ffilters%20results%20-%20Canada.ca&amp;ro=false&amp;summary=&amp;source="*/}
                {/*                            className="shr-lnk linkedin btn btn-default"*/}
                {/*                            rel="noreferrer noopener">LinkedIn®</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://www.myspace.com/Modules/PostTo/Pages/?u=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page&amp;t=Search%20facets%2Ffilters%20results%20-%20Canada.ca"*/}
                {/*                            className="shr-lnk myspace btn btn-default"*/}
                {/*                            rel="noreferrer noopener">MySpace</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://www.pinterest.com/pin/create/button/?url=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page&amp;media=&amp;description=Search%20facets%2Ffilters%20results%20-%20Canada.ca"*/}
                {/*                            className="shr-lnk pinterest btn btn-default"*/}
                {/*                            rel="noreferrer noopener">Pinterest</a></li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://reddit.com/submit?url=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page&amp;title=Search%20facets%2Ffilters%20results%20-%20Canada.ca"*/}
                {/*                            className="shr-lnk reddit btn btn-default"*/}
                {/*                            rel="noreferrer noopener">reddit</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://tinyurl.com/create.php?url=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page"*/}
                {/*                            className="shr-lnk tinyurl btn btn-default"*/}
                {/*                            rel="noreferrer noopener">TinyURL</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://www.tumblr.com/share/link?url=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page&amp;name=Search%20facets%2Ffilters%20results%20-%20Canada.ca&amp;description="*/}
                {/*                            className="shr-lnk tumblr btn btn-default"*/}
                {/*                            rel="noreferrer noopener">tumblr</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://twitter.com/intent/tweet?text=Search%20facets%2Ffilters%20results%20-%20Canada.ca&amp;url=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page"*/}
                {/*                            className="shr-lnk twitter btn btn-default"*/}
                {/*                            rel="noreferrer noopener">Twitter</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://api.whatsapp.com/send?text=Search%20facets%2Ffilters%20results%20-%20Canada.ca%0A%0Ahttps%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page"*/}
                {/*                            className="shr-lnk whatsapp btn btn-default"*/}
                {/*                            rel="noreferrer noopener">Whatsapp</a>*/}
                {/*                        </li>*/}
                {/*                        <li><a*/}
                {/*                            href="https://compose.mail.yahoo.com/?to=&amp;subject=Search%20facets%2Ffilters%20results%20-%20Canada.ca&amp;body=https%3A%2F%2Fwet-boew.github.io%2FGCWeb%2Ftemplates%2Fsearch%2Fresults-filters-en.html%23URL-of-the-advance-search-page%0A"*/}
                {/*                            className="shr-lnk yahoomail btn btn-default" rel="noreferrer noopener">Yahoo!*/}
                {/*                            Mail</a></li>*/}
                {/*                    </ul>*/}
                {/*                    <p className="col-sm-12 shr-dscl">No endorsement of any products or services is*/}
                {/*                        expressed or*/}
                {/*                        implied.</p>*/}
                {/*                    <div className="clearfix"></div>*/}
                {/*                </div>*/}
                {/*                <div className="modal-footer">*/}
                {/*                    <button title="Close overlay"*/}
                {/*                            className="btn btn-sm btn-primary pull-left popup-modal-dismiss"*/}
                {/*                            type="button">Close<span*/}
                {/*                        className="wb-inv">Close overlay</span></button>*/}
                {/*                </div>*/}
                {/*            </section>*/}
                {/*            <a href="#shr-pg0" aria-controls="shr-pg0"*/}
                {/*               className="shr-opn wb-lbx btn btn-default btn-block wb-lbx-inited wb-init"*/}
                {/*               id="wb-auto-7"><span*/}
                {/*                className="glyphicon glyphicon-share"></span>Share this page</a></div>*/}
                {/*    </div>*/}
                {/*    <dl id="wb-dtmd">*/}
                {/*        <dt>{"Date modified:"}</dt>*/}
                {/*        <dd>*/}
                {/*            <time*/}
                {/*                property="dateModified">&nbsp;{props.dateModified ? props.dateModified : "2019-02-06"}</time>*/}
                {/*        </dd>*/}
                {/*    </dl>*/}
                {/*</div>*/}


            </>)
    }
}
export default PageDetails;