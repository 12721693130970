import L from 'leaflet'
import { removeAccents } from '../tools/stringFunctions';

const lang = process.env.LANG;
const isFrench = lang === 'FR';
let sanitizeURL = require( './../tools/stringFunctions' ).sanitizeURL;

const getMapping = ( mapping ) => {
    const mappings = {
        title: 'title',
        description: 'description',
        desc: 'description',
        headline: 'headline',
        head: 'head',
        mission: 'mission',
        appointmentProcessNo: 'ProcessNumber',
        salary: 'Salary',
        contractType: 'ContractType',
        termDetails: 'TermDetails',
        jobDetails: 'JobFunction',
        classification: 'Classification',
        location: 'Location',
        numberOfVacancies: 'NumberOfVacancies',
        closeDate: 'CloseDate',
        appointmentProcessNoFr: 'ProcessNumber',
        salaryFR: 'SalaryFR',
        contractTypeFR: 'ContractTypeFR',
        termDetailsFR: 'TermDetailsFR',
        jobDetailsFR: 'JobFunctionFR',
        classificationFR: 'Classification',
        locationFR: 'LocationFR',
        numberOfVacanciesFR: 'NumberOfVacancies',
        closeDateFR: 'CloseDateFR',
        listDate: 'listDate'
    }
    return mappings[mapping];
}
const distance = ( lat1, lon1, lat2, lon2, unit ) => {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin( Δφ / 2 ) * Math.sin( Δφ / 2 ) + Math.cos( φ1 ) * Math.cos( φ2 ) * Math.sin( Δλ / 2 ) * Math.sin( Δλ / 2 );
    const c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );
    const d = R * c; // in metres
    return d / 1000
}
const makeRandomKey = ( length ) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt( Math.floor( Math.random() * charactersLength ) );
    }
    return result;
}

const createPopups = ( feature = {}, layer ) => {
    const {properties = {}} = feature
    const {location, locationFR, jobs, urlSymbol, symbol, country, countryFR} = properties
    const popup = L.popup()
    //Track which markers are being actively displayed on the map
    function capitalize(s)
    {
        return s[0].toUpperCase() + s.slice(1);
    }
    let jobsHtml = ``;
    for (let job in jobs) {
        let url = new URL( jobs[job]['url'].toString() )
        let locale = url.searchParams.get( 'locale' );
        let careerText = process.env.LANG === 'FR' ? 'carrieres' : 'careers';
        let langSlug = process.env.LANG === 'FR' ? '/fr/' : '/en/';
        let slug = jobs[job].headline.toString() + '-' + urlSymbol + '-' + jobs[job].path.split( '-' )[0].replace( '/processes/', '' ) + '-' + locale;
        slug = sanitizeURL( slug );
        let posPath = langSlug + careerText + '/' + slug;


        if (job > 2) {
            continue;
        }
        jobsHtml += `<li class="${job % 2 === 0 ? 'c-odd' : 'c-even'}">
                        <a class="r ac jsb" target="_blank" href="${posPath}">
                            <h4 class="c">${jobs[job].headline.toString()}</h4> 
                            <p>${jobs[job].city.toString()}</p>
                            <p>${isFrench ? 'Date de clôture: ' : 'Closing Date: '}${jobs[job].closeDate.toString()}</p>
                        </a>
                    </li>`
    }
    let langSlug = process.env.LANG === 'FR' ? '/fr/recherche/?term=' : '/en/search/?term=';
    let opening = isFrench ? 'Ouverture' : 'Opening';
    let openings = isFrench ? 'Ouvertures' : 'Openings';
    const html = `
    <div class="popup-container">
        <h3 class="popup-header">
            <a target="_blank" href="${langSlug}${isFrench ? countryFR : country}">${isFrench? capitalize(locationFR.toString()) :location.toString()}</a>
        </h3>
        <p class="openings">${jobs.length} - ${jobs.length === 1 ? opening : openings}</p>
        <ul class="job-list"> 
            ${jobsHtml}
        </ul>
        <p class="load-all-jobs"><a ${isFrench ? 'style=\'font-size: 15px;\'' : ''} href="${isFrench ? '/fr/recherche' : '/en/search'}/?term=${symbol}">${isFrench ? 'Voir tous les emplois à cet endroit ≫' : 'Load all jobs at this location ≫'}</a></p>
    </div>
    `
    popup.setContent( html )
    // popup.offset([0, 20])
    layer.bindPopup( popup, {offset: [0, 0]} )
}
const getMonthNumber = ( str, lang ) => {
    let value = adjustSearchParams( str, lang );
    value = removeAccents(value);
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    if (lang === 'FR') {
        months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre']
    }
    let month = (months.indexOf( value ) + 1).toString();
    return months.indexOf( value ) + 1 < 10 ? `0${month}` : `${month}`;
}

const adjustSearchParams = ( value, lang ) => {
    if (!value) return;
    let param = value.toString();
    if (param.includes( ' // ' )) {
        if (lang === 'FR') {
            let options = param.split( ' // ' );
            if (options.length === 2) {
                return options[1];
            } else if (options.length === 1) {
                return options[0];
            } else {
                return 'N/A'
            }
        } else {
            let options = param.split( ' // ' );
            if (options.length >= 1) {
                return options[0];
            } else {
                return 'N/A'
            }
        }
    } else if (param.includes( ' / ' )) {
        if (lang === 'FR') {
            let options = param.split( ' / ' );
            if (options.length === 2) {
                return options[1];
            } else if (options.length === 1) {
                return options[0];
            } else {
                return 'N/A'
            }
        } else {
            let options = param.split( ' / ' );
            if (options.length >= 1) {
                return options[0];
            } else {
                return 'N/A'
            }
        }

    } else {
        if (param) {
            return param;
        } else {
            return 'N/A'

        }
    }
}

const createClusters = ( cluster ) => {
    const childCount = cluster.getChildCount()
    let size = ''
    if (childCount < 10) {
        size = 'small'
    } else if (childCount < 25) {
        size = 'medium'
    } else {
        size = 'large'
    }
    return L.divIcon( {
        html:
            `<div class="row justify-content-center align-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21.992 24">
                    <path id="Path_Copy" data-name="Path Copy" d="M9.069,3.929,11.018,0l1.935,3.786c.241.4.437.367.823.176l1.664-.814L14.357,8.434c-.226,1.038.371,1.343,1.019.638l2.372-2.509.63,1.42c.211.429.53.367.952.29l2.45-.51-.823,3.063v.066c-.1.4-.289.734.163.928l.871.429-5.07,4.23c-.515.525-.337.686-.145,1.277l.467,1.42-4.71-.844c-.582-.143-.986-.143-1,.319L11.726,24H10.31l.193-5.334c0-.525-.4-.51-1.356-.316l-4.369.829.563-1.42c.193-.543.245-.91-.193-1.277L0,12.312l.952-.576c.274-.209.289-.429.145-.9L.13,7.741l2.483.525c.693.161.886,0,1.064-.367l.693-1.405L6.823,9.208c.434.51,1.049.176.856-.558L6.5,2.92,8.324,3.958c.289.176.6.224.775-.11" fill="#fff"/>
                </svg>
                <span>
                    <b>${childCount}</b>
                </span>
            </div>`,
        className: `custom-marker-cluster custom-marker-cluster-${size}`,
        iconSize: new L.point( 54, 54 ),
    } )
}

export default distance;
export { distance, makeRandomKey, createPopups, createClusters, getMapping, adjustSearchParams, getMonthNumber };

